import { grid } from '@mixins';
import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import SectionTitle from '@components/SectionTitle';
import mq from '@mq';
import Image from '@core/Image';
import Link from '@core/Link';

const Team = () => {
  const {
    filip: { childImageSharp: filip },
    rainer: { childImageSharp: rainer },
    dimitrij: { childImageSharp: dimitrij },
    christoph: { childImageSharp: christoph },
  } = useStaticQuery(graphql`
    query TeamQuery {
      filip: file(name: { eq: "filip" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 900
            quality: 75
          )
        }
      }
      christoph: file(name: { eq: "christoph" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 900
            quality: 75
          )
        }
      }
      dimitrij: file(name: { eq: "dimitrij" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 900
            quality: 75
          )
        }
      }
      rainer: file(name: { eq: "rainer" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 900
            quality: 75
          )
        }
      }
    }
  `);

  return (
    <Container id="team">
      <SectionTitle>Team</SectionTitle>
      <Members>
        <Member>
          <ImageContainer>
            <Image image={filip} />
          </ImageContainer>
          <Title>Filip Blodinger</Title>
          <Subtitle>Chairman</Subtitle>
          <Subtitle>Flemings Asset Management GmbH</Subtitle>
          <Description to="https://www.linkedin.com/in/filip-blodinger-62b56612a/">
            LinkedIn
          </Description>
        </Member>
        <Member>
          <ImageContainer>
            <Image image={rainer} />
          </ImageContainer>
          <Title>Rainer Rotermund</Title>
          <Subtitle>Geschäftsführer</Subtitle>
          <Subtitle>Flemings Asset Management GmbH</Subtitle>
          <Description to="https://www.linkedin.com/in/rainer-rotermund-07390614/?originalSubdomain=de">
            LinkedIn
          </Description>
        </Member>
        <Member>
          <ImageContainer>
            <Image image={dimitrij} />
          </ImageContainer>
          <Title>Dimitrij Jasvoin</Title>
          <Subtitle>Geschäftsführer</Subtitle>
          <Subtitle>Flemings Tech GmbH</Subtitle>
          <Description to="https://www.linkedin.com/in/dimitrij-jasvoin-199670145/">
            LinkedIn
          </Description>
        </Member>
        <Member>
          <ImageContainer>
            <Image image={christoph} />
          </ImageContainer>
          <Title>Christoph Salzer</Title>
          <Subtitle>Chief Development Officer</Subtitle>
          <Subtitle>Flemings Asset Management GmbH</Subtitle>
          <Description to="http://www.linkedin.com/in/christoph-salzer/?originalSubdomain=at">
            LinkedIn
          </Description>
        </Member>
      </Members>
    </Container>
  );
};

const Container = styled.div`
  ${grid};

  background-color: var(--color-black-olive);
  color: var(--color-white);

  padding-top: 3rem;
  padding-bottom: 6rem;

  ${mq.small} {
    padding-top: 6rem;
    padding-bottom: 12rem;
  }
`;

const Members = styled.ul`
  ${grid({ gutter: false })};
  margin-top: 3rem;
  grid-column: 1 / -1;
  row-gap: 5.375rem;

  ${mq.small} {
    margin-top: 6rem;
  }
`;

const Member = styled.li`
  grid-column-end: span 2;

  ${mq.small} {
    grid-column-end: span 3;
  }
`;

const ImageContainer = styled.div`
  ${mq.small} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var(--grid-gap);

    figure {
      grid-column: 1 / 3;
      max-width: 17rem;
    }
  }

  figure,
  img {
    border-radius: 50%;
    overflow: hidden;
  }
`;

const Title = styled.div`
  font: var(--font-team-title);
  margin-top: 2rem;

  ${mq.small} {
    margin-top: 3.125rem;
  }
`;

const Subtitle = styled.div`
  font: var(--font-team-subtitle);

  margin-top: 0.625rem;

  ${mq.small} {
    margin-top: 0.5rem;
  }
`;

const Description = styled(Link)`
  font: var(--font-team-description);
  margin-top: 1rem;
  display: block;

  ${mq.small} {
    margin-top: 0.5rem;
  }
`;

export default Team;
